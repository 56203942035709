import React from "react";

import "./footer.css"
class Footer extends React.Component{

    render(){
        return(
            <div className="footer_layout">
                <div className="footer_section">
                    <div className="footer_text_room">
                        <p className="footer_title">PARTNERSHIPS</p>
                    </div>
                    <div className="footer_bg_1">
                       
                    </div>
                </div>
            </div>
        )
    }
}
export default Footer;
